import _extends from "@babel/runtime/helpers/extends";
import { validHex } from './utils';
export * from './utils';
export * from './getContrastingColor';
var RGB_MAX = 255;
var HUE_MAX = 360;
var SV_MAX = 100;
/**
 * ```js
 * rgbaToHsva({ r: 255, g: 255, b: 255, a: 1 }) //=> { h: 0, s: 0, v: 100, a: 1 }
 * ```
 */
export var rgbaToHsva = _ref => {
  var {
    r,
    g,
    b,
    a
  } = _ref;
  var max = Math.max(r, g, b);
  var delta = max - Math.min(r, g, b);

  // prettier-ignore
  var hh = delta ? max === r ? (g - b) / delta : max === g ? 2 + (b - r) / delta : 4 + (r - g) / delta : 0;
  return {
    h: 60 * (hh < 0 ? hh + 6 : hh),
    s: max ? delta / max * SV_MAX : 0,
    v: max / RGB_MAX * SV_MAX,
    a
  };
};
export var hsvaToHslString = hsva => {
  var {
    h,
    s,
    l
  } = hsvaToHsla(hsva);
  // return `hsl(${h}, ${s}%, ${l}%)`;
  return "hsl(" + h + ", " + Math.round(s) + "%, " + Math.round(l) + "%)";
};
export var hsvaToHsvString = _ref2 => {
  var {
    h,
    s,
    v
  } = _ref2;
  return "hsv(" + h + ", " + s + "%, " + v + "%)";
};
export var hsvaToHsvaString = _ref3 => {
  var {
    h,
    s,
    v,
    a
  } = _ref3;
  return "hsva(" + h + ", " + s + "%, " + v + "%, " + a + ")";
};
export var hsvaToHslaString = hsva => {
  var {
    h,
    s,
    l,
    a
  } = hsvaToHsla(hsva);
  return "hsla(" + h + ", " + s + "%, " + l + "%, " + a + ")";
};
export var hslStringToHsla = str => {
  var [h, s, l, a] = (str.match(/\d+/g) || []).map(Number);
  return {
    h,
    s,
    l,
    a
  };
};
export var hslaStringToHsva = hslString => {
  var matcher = /hsla?\(?\s*(-?\d*\.?\d+)(deg|rad|grad|turn)?[,\s]+(-?\d*\.?\d+)%?[,\s]+(-?\d*\.?\d+)%?,?\s*[/\s]*(-?\d*\.?\d+)?(%)?\s*\)?/i;
  var match = matcher.exec(hslString);
  if (!match) return {
    h: 0,
    s: 0,
    v: 0,
    a: 1
  };
  return hslaToHsva({
    h: parseHue(match[1], match[2]),
    s: Number(match[3]),
    l: Number(match[4]),
    a: match[5] === undefined ? 1 : Number(match[5]) / (match[6] ? 100 : 1)
  });
};
export var hslStringToHsva = hslaStringToHsva;
export var hslaToHsva = _ref4 => {
  var {
    h,
    s,
    l,
    a
  } = _ref4;
  s *= (l < 50 ? l : SV_MAX - l) / SV_MAX;
  return {
    h: h,
    s: s > 0 ? 2 * s / (l + s) * SV_MAX : 0,
    v: l + s,
    a
  };
};
export var hsvaToHsla = _ref5 => {
  var {
    h,
    s,
    v,
    a
  } = _ref5;
  var hh = (200 - s) * v / SV_MAX;
  return {
    h,
    s: hh > 0 && hh < 200 ? s * v / SV_MAX / (hh <= SV_MAX ? hh : 200 - hh) * SV_MAX : 0,
    l: hh / 2,
    a
  };
};
export var hsvaStringToHsva = hsvString => {
  var matcher = /hsva?\(?\s*(-?\d*\.?\d+)(deg|rad|grad|turn)?[,\s]+(-?\d*\.?\d+)%?[,\s]+(-?\d*\.?\d+)%?,?\s*[/\s]*(-?\d*\.?\d+)?(%)?\s*\)?/i;
  var match = matcher.exec(hsvString);
  if (!match) return {
    h: 0,
    s: 0,
    v: 0,
    a: 1
  };
  return {
    h: parseHue(match[1], match[2]),
    s: Number(match[3]),
    v: Number(match[4]),
    a: match[5] === undefined ? 1 : Number(match[5]) / (match[6] ? SV_MAX : 1)
  };
};

/**
 * Valid CSS <angle> units.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/angle
 */
var angleUnits = {
  grad: HUE_MAX / 400,
  turn: HUE_MAX,
  rad: HUE_MAX / (Math.PI * 2)
};
export var parseHue = function parseHue(value, unit) {
  if (unit === void 0) {
    unit = 'deg';
  }
  return Number(value) * (angleUnits[unit] || 1);
};
export var hsvStringToHsva = hsvaStringToHsva;
export var rgbaStringToHsva = rgbaString => {
  var matcher = /rgba?\(?\s*(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?,?\s*[/\s]*(-?\d*\.?\d+)?(%)?\s*\)?/i;
  var match = matcher.exec(rgbaString);
  if (!match) return {
    h: 0,
    s: 0,
    v: 0,
    a: 1
  };
  return rgbaToHsva({
    r: Number(match[1]) / (match[2] ? SV_MAX / RGB_MAX : 1),
    g: Number(match[3]) / (match[4] ? SV_MAX / RGB_MAX : 1),
    b: Number(match[5]) / (match[6] ? SV_MAX / RGB_MAX : 1),
    a: match[7] === undefined ? 1 : Number(match[7]) / (match[8] ? SV_MAX : 1)
  });
};
export var rgbStringToHsva = rgbaStringToHsva;

/** Converts an RGBA color plus alpha transparency to hex */
export var rgbaToHex = _ref6 => {
  var {
    r,
    g,
    b
  } = _ref6;
  var bin = r << 16 | g << 8 | b;
  return "#" + (h => new Array(7 - h.length).join('0') + h)(bin.toString(16));
};
export var rgbaToHexa = _ref7 => {
  var {
    r,
    g,
    b,
    a
  } = _ref7;
  var alpha = typeof a === 'number' && (a * 255 | 1 << 8).toString(16).slice(1);
  return "" + rgbaToHex({
    r,
    g,
    b,
    a
  }) + (alpha ? alpha : '');
};
export var hexToHsva = hex => rgbaToHsva(hexToRgba(hex));
export var hexToRgba = hex => {
  var htemp = hex.replace('#', '');
  if (/^#?/.test(hex) && htemp.length === 3) {
    hex = "#" + htemp.charAt(0) + htemp.charAt(0) + htemp.charAt(1) + htemp.charAt(1) + htemp.charAt(2) + htemp.charAt(2);
  }
  var reg = new RegExp("[A-Za-z0-9]{2}", 'g');
  var [r, g, b = 0, a] = hex.match(reg).map(v => parseInt(v, 16));
  return {
    r,
    g,
    b,
    a: a ? a / RGB_MAX : 1
  };
};

/**
 * Converts HSVA to RGBA. Based on formula from https://en.wikipedia.org/wiki/HSL_and_HSV
 * @param color HSVA color as an array [0-360, 0-1, 0-1, 0-1]
 */
export var hsvaToRgba = _ref8 => {
  var {
    h,
    s,
    v,
    a
  } = _ref8;
  var _h = h / 60,
    _s = s / SV_MAX,
    _v = v / SV_MAX,
    hi = Math.floor(_h) % 6;
  var f = _h - Math.floor(_h),
    _p = RGB_MAX * _v * (1 - _s),
    _q = RGB_MAX * _v * (1 - _s * f),
    _t = RGB_MAX * _v * (1 - _s * (1 - f));
  _v *= RGB_MAX;
  var rgba = {};
  switch (hi) {
    case 0:
      rgba.r = _v;
      rgba.g = _t;
      rgba.b = _p;
      break;
    case 1:
      rgba.r = _q;
      rgba.g = _v;
      rgba.b = _p;
      break;
    case 2:
      rgba.r = _p;
      rgba.g = _v;
      rgba.b = _t;
      break;
    case 3:
      rgba.r = _p;
      rgba.g = _q;
      rgba.b = _v;
      break;
    case 4:
      rgba.r = _t;
      rgba.g = _p;
      rgba.b = _v;
      break;
    case 5:
      rgba.r = _v;
      rgba.g = _p;
      rgba.b = _q;
      break;
  }
  rgba.r = Math.round(rgba.r);
  rgba.g = Math.round(rgba.g);
  rgba.b = Math.round(rgba.b);
  return _extends({}, rgba, {
    a
  });
};
export var hsvaToRgbString = hsva => {
  var {
    r,
    g,
    b
  } = hsvaToRgba(hsva);
  return "rgb(" + r + ", " + g + ", " + b + ")";
};
export var hsvaToRgbaString = hsva => {
  var {
    r,
    g,
    b,
    a
  } = hsvaToRgba(hsva);
  return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
};
export var rgbaToRgb = _ref9 => {
  var {
    r,
    g,
    b
  } = _ref9;
  return {
    r,
    g,
    b
  };
};
export var hslaToHsl = _ref10 => {
  var {
    h,
    s,
    l
  } = _ref10;
  return {
    h,
    s,
    l
  };
};
export var hsvaToHex = hsva => rgbaToHex(hsvaToRgba(hsva));
export var hsvaToHexa = hsva => rgbaToHexa(hsvaToRgba(hsva));
export var hsvaToHsv = _ref11 => {
  var {
    h,
    s,
    v
  } = _ref11;
  return {
    h,
    s,
    v
  };
};
export var color = str => {
  var rgb;
  var hsl;
  var hsv;
  var rgba;
  var hsla;
  var hsva;
  var hex;
  var hexa;
  if (typeof str === 'string' && validHex(str)) {
    hsva = hexToHsva(str);
    hex = str;
  } else if (typeof str !== 'string') {
    hsva = str;
  }
  if (hsva) {
    hsv = hsvaToHsv(hsva);
    hsla = hsvaToHsla(hsva);
    rgba = hsvaToRgba(hsva);
    hexa = rgbaToHexa(rgba);
    hex = hsvaToHex(hsva);
    hsl = hslaToHsl(hsla);
    rgb = rgbaToRgb(rgba);
  }
  return {
    rgb,
    hsl,
    hsv,
    rgba,
    hsla,
    hsva,
    hex,
    hexa
  };
};