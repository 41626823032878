import { EditLayout, StringLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const editConfig = () => {
  const config: EditLayout = {
    version: 1,
    entities: [
      {
        entity: 'Product',
        elements: [
          {
            name: 'id',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'productType',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'nettoPrice',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'lastUpdate',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'description',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'imageIds',
            editTab: 'files',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'name',
            layout: StringLayout.multiline,
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'fileIds',
            editTab: 'files',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'crudeIncomingReference',
            sourceEntity: 'OrderingHasProduct',
            sourceColumn: 'product',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 5,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            description: {
              'de-DE': 'Hier kommen die wesentlichen Inhalte rein',
              'en-US': 'Main content',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                title: {
                  'de-DE': 'Produktdaten',
                  'en-US': 'Product Data',
                },
                name: 'productData',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                title: {
                  'de-DE': 'Produktdetails',
                  'en-US': 'Product details',
                },
                name: 'productDetails',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 4,
                    to: 5,
                  },
                },
                title: {
                  'de-DE': 'Bestellungen',
                  'en-US': 'orders',
                },
                name: 'orders',
                description: {
                  'de-DE': 'Hier werden die Produkte Bestellungen zugeordnet',
                  'en-US': 'Products in different orders',
                },
                initialOpen: false,
                closable: true,
              },
            ],
          },
          {
            title: {
              'de-DE': 'Dateien',
              'en-US': 'Files',
            },
            name: 'files',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 1,
                  },
                },
                name: 'additional',
                title: {
                  'de-DE': 'Anhänge',
                  'en-US': 'Additional',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Customer',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'customerNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'type',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'firstName',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'lastName',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'company',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'ustId',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'address',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'plz',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'city',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'countryId',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'mail',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'crudeActionElement',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'phoneNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Dateien',
              'en-US': 'Files',
            },
            name: 'files',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 4,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 4,
                  },
                },
                name: 'address',
                title: {
                  'de-DE': 'Adresse',
                  'en-US': 'Adresse',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 5,
                    to: 6,
                  },
                },
                name: 'contact',
                title: {
                  'de-DE': 'Kontakt',
                  'en-US': 'Contact',
                },
                initialOpen: false,
                closable: true,
              },
            ],
          },
        ],
      },
    ],
  }
  return config
}
export default editConfig
