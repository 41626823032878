"use strict";
/**
 * isJsonString
 */
Object.defineProperty(exports, "__esModule", { value: true });
var isJsonString = function (str) {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
exports.default = isJsonString;
