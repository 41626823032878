"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var schemaTypes_1 = require("../../../shared/types/schemaTypes");
var getDefaultValueForColumnType = function (columnType) {
    switch (columnType) {
        case schemaTypes_1.ColumnType.String:
            return null;
        case schemaTypes_1.ColumnType.HtmlRichText:
            return null;
        case schemaTypes_1.ColumnType.Boolean:
            return false;
        case schemaTypes_1.ColumnType.Date:
            return null;
        case schemaTypes_1.ColumnType.Time:
            return null;
        case schemaTypes_1.ColumnType.DateTime:
            return null;
        case schemaTypes_1.ColumnType.Number:
            return null;
        case schemaTypes_1.ColumnType.Json:
            return '';
    }
};
exports.default = getDefaultValueForColumnType;
