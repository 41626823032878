"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * getColumnEditLayout
 */
var getColumnEditLayout = function (entity, columnName, editLayout) {
    var entityEditLayout = editLayout && editLayout.entities.find(function (elem) { return elem.entity === entity; });
    var columnEditLayout;
    entityEditLayout && entityEditLayout.elements.forEach(function (element) {
        if (element.name === columnName) {
            columnEditLayout = element;
        }
    });
    return columnEditLayout;
};
exports.default = getColumnEditLayout;
