"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeLanguage = exports.resetTable = void 0;
var crude_actions_1 = require("./crude/crude.actions");
/**
 * These actions are for use OUTSIDE ( in Parent Project ) of Crude
 * do NOT call them INSIDE the Crude Project
 *
 * they will be handled inside the corresponding store reducer or saga
 */
// crude
exports.resetTable = crude_actions_1.actionCreator('RESET_TABLE');
exports.changeLanguage = crude_actions_1.actionCreator('CHANGE_LANGUAGE');
