"use strict";
/**
 * getCrudeAppTabs(crudeConfiguration: CrudeConfiguration, crudeSchema: CrudeSchema)
 * Liefert ein Array zurück in dem alle Tabs enthalten sind, die angezeigt werden sollen.
 * Wenn vom Parent ein TabArray in der crudeConfiguration mitgegeben wird, werden diese Tabs inkl. ihrer Labels und TechFilter verwendet.
 * Dabei wird aber geprüft, ob der User die Berechtigung hat, diese Tabs zu sheen, ansonsten werden sie ignoriert.
 * Ansonsten werden die Tabs aus dem Schema generiert und zurückgegeben. Labels werden dabei momentan noch nicht berücksictigt.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var getCrudeAppTabs = function (extendedNavigationLicense, crudeConfiguration, crudeSchema) {
    var crudeAppTabs = [];
    // hier werden alle Tabs aus der CrudeConfiguration extrahiert
    if (extendedNavigationLicense && crudeConfiguration.tabs) {
        crudeConfiguration.tabs.forEach(function (tab) {
            // Prüfung, ob dieser Tab im Schema enthalten ist
            var tabElement = crudeSchema.find(function (entitySchema) { return entitySchema.name === tab.entity; });
            if (tabElement && !tabElement.isJoinEntity) {
                crudeAppTabs.push(tab);
            }
        });
    }
    // hier werden alle Tabs aus den Schema generiert und ggf. hinten angefügt, falls noch nicht verfügbar > label kommen später hinzu
    crudeSchema.map(function (entity) {
        if (!entity.isJoinEntity) {
            var tabElement = crudeAppTabs.find(function (configTab) { return configTab.entity === entity.name; });
            if (!tabElement) {
                crudeAppTabs.push({
                    entity: entity.name,
                });
            }
        }
    });
    return crudeAppTabs;
};
exports.default = getCrudeAppTabs;
