import React, { useEffect, useState } from 'react'
import { createStore } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { CssBaseline, ThemeProvider, Theme, Stack, Box } from '@mui/material'

import Env from '../../shared/config/envConfig'
import TableActionElement from '../../screens/App/TableActionElement'
import {
  getAppLayout,
  getTheme,
  getPrefersDarkMode,
  getCrudeAppName,
  getUser,
} from '../../store/settings/settings.selectors'
import * as settingsActions from '../../store/settings/settings.actions'
import Settings from '../../screens/App/screens/Settings'
import appConfig from '../../shared/config/appConfig'
import editConfig from '../../shared/config/editConfig'
import { getAppTheme } from '../../shared/util/getAppTheme'

// Import CRUDE
import { crudeRootReducer } from '@3m5/crude-frontend/dist/store/crudeRoot.reducer'
import { useCrude, CrudeContext } from '@3m5/crude-frontend/dist/crudeHooks'
import SnackbarComponent from '../../shared/components/MessageComponents/SnackbarComponent'
import DemoDashboard from './screens/DemoDashboard'
import { License } from '@3m5/crude-frontend/dist/shared/types/licenseTypes'
import { Order, TableTextAlignment } from '@3m5/crude-frontend/dist/shared/types/commonTypes'
import getDefaultColor from '../../shared/config/getDefaultColor'
import Login from '../../shared/components/Login'
import { useHistory } from 'react-router-dom'
import { setLogo, setUser } from '../../store/settings/settings.actions'
import Header from '../../shared/components/Navigation/Header'

const store2 = createStore(
  crudeRootReducer,
)

const App: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(getUser)
  const darkMode: boolean = useSelector(getPrefersDarkMode)
  const storeAppLayout = useSelector(getAppLayout)
  const storedAppTheme = useSelector(getTheme)
  const crudeAppName = useSelector(getCrudeAppName)

  useEffect(() => {
    const initialTheme = getAppTheme(darkMode, getDefaultColor())
    dispatch(settingsActions.setTheme(initialTheme))
  },
  [],
  )

  const [theme, setTheme] = useState<Theme>(getAppTheme(darkMode, getDefaultColor()))

  useEffect(() => {
    const oldMode = localStorage.getItem('darkMode') === 'true'
    setTheme(storedAppTheme)
    store2.getState().crudeStore.darkMode = darkMode
    if (oldMode !== darkMode) {
      localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
      dispatch(setLogo(darkMode ? '/logoDeviDark.png' : '/logoDeviLight.png'))
      location.reload()
    }
  },
  [storedAppTheme, darkMode],
  )

  useEffect(() => {
    let pathname = ''
    if (!user) {
      pathname = 'login'
    }
    history.push(`/${pathname}`)
  }, [history, user],
  )

  useEffect(() => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    fetch(`${Env.restBase}/api/session/user`,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        !json.error && dispatch(setUser(json))
      })
      .catch(err => console.log(err))
  }, [])

  const configuration4: any =
  {
    theme: theme,
    path: '/apps/demo',
    api: {
      app: 'demo',
      restPath: `${Env.crudeRestBase}`,
    },
    crudeLicense: {
      [License.outgoingReferenceFilters]: {
        available: true,
      },
      [License.standardFilter]: {
        available: true,
      },
      [License.extendedEditForms]: {
        available: true,
      },
      [License.i18n]: {
        available: true,
      },
      [License.extendedNavigation]: {
        available: true,
      },
      [License.richTextEditor]: {
        available: true,
      },
      [License.extendedTable]: {
        available: true,
      },
      [License.configurableTable]: {
        available: true,
      },
      [License.fileUpload]: {
        available: true,
      },
      [License.treeView]: {
        available: true,
      },
      [License.tooltips]: {
        available: true,
      },
      [License.freeJSON]: {
        available: true,
      },
      [License.integratedHTML]: {
        available: true,
      },
      [License.imageCropper]: {
        available: true,
      },
      [License.responsiveViews]: {
        available: true,
      },
    },
    tabs: [
      {
        entity: 'Product',
        hideEntity: false,
        columns: [
          {
            name: 'name',
            hideInTableView: false,
            columnWidth: 120,
            pinned: true,
            sort: Order.asc,
            align: TableTextAlignment.right,
          },
          {
            name: 'description',
            hideInTableView: false,
            columnWidth: 250,
          },
          {
            name: 'fileIDs',
            hideInTableView: true,
          },
          {
            name: 'productType',
            hideInTableView: false,
            pinned: true,
            align: TableTextAlignment.center,
          },
          {
            name: 'crudeAction',
            hideInTableView: false,
            columnWidth: 80,
            pinned: true,
            align: TableTextAlignment.right,
          },
        ],
        tabs: [
          {
            sourceEntity: 'ProductProposal',
            sourceColumn: 'productId',
            hideEntity: true,
          },
          {
            sourceEntity: 'ProductProposal',
            sourceColumn: 'proposalProductId',
            hideEntity: false,
            label: {
              'de-DE': 'Produktvorschläge',
            },
          },
          {
            sourceEntity: 'OrderingHasProduct',
            sourceColumn: 'product',
            hideEntity: false,
            label: {
              'de-DE': 'Bestellungen',
            },
          },
        ],
      },
      {
        entity: 'ProductType',
      },
      {
        entity: 'Ordering',
      },
      {
        entity: 'Customer',
      },
      {
        entity: 'ContactPerson',
      },
      {
        entity: 'Employee',
        standardFilter: [
          {
            columnName: 'isActive',
            multiSelect: true,
          },
        ],
      },
      {
        entity: 'Country',
      },
    ],
    appLayout: appConfig(),
    editLayout: editConfig(),
  }

  const [configuration, setConfiguration] = useState(configuration4)

  useEffect(
    () => {
      if (crudeAppName === 'demo') {
        setConfiguration({
          ...configuration4,
          appLayout: appConfig(storeAppLayout),
          theme: storedAppTheme,
        })
      }
    },
    [crudeAppName, storeAppLayout, storedAppTheme],
  )

  const logoutUser = () => {
    dispatch(settingsActions.setUser(undefined))
    // window.open(
    //   `${Env.restBase}/logout`,
    // );
    fetch(`${Env.restBase}/api/session/logout`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  window.addEventListener('CrudeLogout', (({ detail }: CustomEvent) => {
    logoutUser()
  }) as EventListener)

  window.addEventListener('CrudeSchema', (({ detail }: CustomEvent) => {
    // console.log('event', detail)
  }) as EventListener)

  window.addEventListener('CrudeEntityChange', (({ detail }: CustomEvent) => {
    // console.log('CrudeEntityChange', detail)
  }) as EventListener)

  const DashboardApp = () => (<DemoDashboard theme={theme} user={user} />)
  const SettingsApp = () => (<Settings user={user} />)

  const crudeContext = useCrude({ appPageProps: configuration, tableActionComponent: TableActionElement })
  const [showPlayground, setShowPlayground] = useState<boolean>(JSON.parse(window.localStorage.getItem('showPlayground')) || false)

  return (
    <CrudeContext.Provider value={crudeContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Stack spacing={2} sx={{ width: showPlayground ? 'calc(100% - 500px)' : '100%' }}>
          {user &&
            <>
              <Header
                setShowPlayground={setShowPlayground}
                user={user}
                doLogout={logoutUser}
              />
              <Box sx={{ width: '100%', padding: '20px' }}>
                <Switch>
                  <Route key='crude' path='/apps/demo/' component={crudeContext.Crude} />
                  <Route exact path='/apps/settings' component={SettingsApp} />
                  <Route component={DashboardApp} />
                </Switch>
              </Box>
              {TableActionElement}
            </>}
          <Switch>
            <Route exact path='/login' component={Login} />
          </Switch>
          <SnackbarComponent />
        </Stack>
      </ThemeProvider>
    </CrudeContext.Provider>
  )
}

export default App
