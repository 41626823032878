"use strict";
/**
 * getOutputLabelFromBytes
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileSizeUnit = void 0;
var FileSizeUnit;
(function (FileSizeUnit) {
    FileSizeUnit["B"] = "B";
    FileSizeUnit["kB"] = "kB";
    FileSizeUnit["MB"] = "MB";
    FileSizeUnit["GB"] = "GB";
})(FileSizeUnit = exports.FileSizeUnit || (exports.FileSizeUnit = {}));
var getOutputLabelFromBytes = function (bytes) {
    var unit = FileSizeUnit.B;
    var value = bytes;
    if (bytes / 1024 > 1 && bytes / 1024 < 1024) {
        unit = FileSizeUnit.kB;
        value = bytes / 1024;
    }
    if (bytes / 1024 / 1024 > 1 && bytes / 1024 / 1024 < 1024) {
        unit = FileSizeUnit.MB;
        value = bytes / 1024 / 1024;
    }
    if (bytes / 1024 / 1024 / 1024 > 1) {
        unit = FileSizeUnit.GB;
        value = bytes / 1024 / 1024 / 1024;
    }
    var output = Math.round(value) + " " + unit;
    return output;
};
exports.default = getOutputLabelFromBytes;
