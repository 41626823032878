"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getCompleteConfig = function (crudeConfig, localConfig) {
    var completeConfig = crudeConfig;
    // TODO auf Theme, etc. erweitern
    // Tabs betrachten
    completeConfig.tabs = localConfig.tabs;
    return completeConfig;
};
exports.default = getCompleteConfig;
