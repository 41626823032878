"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var commonTypes_1 = require("../shared/types/commonTypes");
var getDefaultTableFilterSettings = function (appLayout) {
    var rowsPerPageOptions = (appLayout === null || appLayout === void 0 ? void 0 : appLayout.rowsPerPageOptions) || [10, 25, 50, 100];
    var initialPageSize = (appLayout === null || appLayout === void 0 ? void 0 : appLayout.initialPageSize) || rowsPerPageOptions[0];
    if ((rowsPerPageOptions.find(function (elem) { return elem === initialPageSize; })) === -1) {
        initialPageSize = rowsPerPageOptions[0];
    }
    var defaultTableFilterSettings = {
        orderBy: '',
        order: commonTypes_1.Order.asc,
        page: 0,
        pageSize: initialPageSize || 25,
        search: '',
        filter: {},
    };
    return defaultTableFilterSettings;
};
exports.default = getDefaultTableFilterSettings;
