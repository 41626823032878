"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var translateMessage = function (message, languageLabels, entity, id) {
    var text = message.default;
    var translatedMessage = message.key && languageLabels.message[message.key];
    message.parameters && message.parameters.map(function (param) {
        var regEx = "{{" + param.key + "}}";
        translatedMessage = translatedMessage && translatedMessage.replace(regEx, param.value);
    });
    translatedMessage = translatedMessage && translatedMessage.replace('{{_entityName}}', entity);
    id && translatedMessage && translatedMessage.replace('{{_entityId}}', id);
    return translatedMessage || text;
};
exports.default = translateMessage;
