"use strict";
/**
 * getPinPosition(crudeConfiguration: CrudeConfiguration, crudeSchema: CrudeSchema)
 * Liefert den Abstand zum Rand für die Pinned cells
 */
Object.defineProperty(exports, "__esModule", { value: true });
var getPinPosition = function (entityColumns, columnName, entitySchema, width) {
    var _a;
    var pos = entityColumns.findIndex(function (col) { return col.name === columnName; });
    // erstens Position im Context ermitteln und so entscheiden, ob left oder right pinned
    // zweitens die Größe der Felder davor / danach ermitteln, um so die genaue position zu bestimmen
    // wenn treeview aktiv ist, id=treeview dafür hinzufügen (bei left)
    // wenn keine genue Zuordnung möglich ist, dann position: relative setzen
    var treeviewColumnWidth = entitySchema.treeViewIncomingReference ? 60 : 0; // entitySchema.treeViewIncomingReference && window.document.getElementById('treeview')?.offsetWidth
    var positionLeft = treeviewColumnWidth || 0;
    var positionRight = 0;
    var checkRightPosition = 'left';
    entityColumns.map(function (col, index) {
        var _a, _b;
        // 1. Fall: col kommt vor selectedColumn
        if (pos >= 0 && index < pos) {
            positionLeft += ((_a = window.document.getElementById(col.name)) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0;
            if (!col.pinned) {
                checkRightPosition = 'right';
            }
        }
        // 2. Fall: col kommt nach selectedColumn
        if (pos >= 0 && index > pos) {
            if (!col.pinned && checkRightPosition === 'right') {
                checkRightPosition = null;
            }
            positionRight += ((_b = window.document.getElementById(col.name)) === null || _b === void 0 ? void 0 : _b.offsetWidth) || 0;
        }
        // 3. Fall: selectedColumn ist letztes Element
        if (pos >= 0 && pos === entityColumns.length - 1) {
            checkRightPosition = 'right';
        }
    });
    var position = checkRightPosition
        ? (_a = {},
            _a[checkRightPosition] = checkRightPosition === 'left' ? positionLeft : positionRight,
            _a.filter = 'brightness(95%)',
            _a.position = 'sticky',
            _a.zIndex = 999,
            _a.backgroundColor = 'inherit',
            _a.width = width || 'auto',
            _a.minWidth = width || 'auto',
            _a) : { position: 'relative', minWidth: width || 'auto', width: width || 'auto', backgroundColor: 'inherit' };
    return position;
};
exports.default = getPinPosition;
