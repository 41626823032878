{
  "common": {
    "administration": "Verwaltung",
    "projects": "Projekte",
    "quickAccess": "VPS Schnellzugriff",
    "editProfile": "Profil bearbeiten",
    "settings": "Einstellungen",
    "logout": "Abmelden",
    "sendEmail": "E-Mail senden",
    "loginAs": "Angemeldet als",
    "demo": "Demoshop",
    "dashboard": "Dashboard"
  },
  "settings": {
    "username": "Nutzername",
    "email": "E-Mail-Adresse",
    "name": "Klartextname",
    "role": "Rolle",
    "phone": "Telefon",
    "customer": "Kunde",
    "changePassword": "Passwort ändern",
    "oldPassword": "Altes Passwort",
    "newPassword": "Neues Passwort",
    "confirmPassword": "Neues Passwort wiederholen"
  },
  "layout": {
    "playground": "Spielwiese",
    "tabLayout": "Layout",
    "tabTable": "Tabellen",
    "tabEdit": "Editieren",
    "tabRTE": "Texteditor",
    "darkMode": "Dark Mode",
    "referenceFilter": "Referenzfilter anzeigen",
    "standardFilter": "Standardfilter anzeigen",
    "filter": "Filter anzeigen",
    "button": "Button",
    "textfield": "Eingabefeld",
    "embeddedEditHigherMainLevel": "Editieren in Haupttabelle",
    "embeddedEditFormHigherLevel": "Editieren in Subtabellen",
    "maxTreeViewLevel": "Anzahl aufklappbare Ebenen",
    "maxThumbnails": "Anzahl Vorschau-Icons",
    "maxTextLines": "Anzahl Textzeilen in Tabellen",
    "maxJumpLevel": "Anzahl Verlinkungsebenen",
    "initialPageSize": "Seitengröße",
    "pageSizeOptions": "Seitengrößen-Optionen",
    "useImageCropper": "Image Cropper verwenden",
    "switch": "Switch",
    "checkbox": "Checkbox",
    "dropdown": "Dropdown",
    "radio": "Radio",
    "references": "Referenzauswahl",
    "popUp": "PopUp",
    "list": "Liste",
    "autocomplete": "Autocomplete",
    "infoIcon": "Info Icon",
    "deleteIcon": "Delete Icon",
    "disabledInputValue": "Gesperrter Inhalt",
    "hideNumberInputArrows": "Nummernfeld",
    "radioDirection": "Ausrichtung",
    "radioColumn": "Vertikal",
    "radioRow": "Horizontal",
    "textfields": "Textfelder",
    "maxRowsPerDialogPage": "Anzahl Tabellenzeilen im Popup",
    "maxDropdownSize": "Maximale Anzahl Elemente im Dropdown",
    "minRowsMultiline": "Mindestzeilen mehrzeilige Textfelder",
    "leftHeader": "Seitliche Navigation",
    "changePrimaryColor": "Primärfarbe ändern",
    "changeLogo": "Logo ändern",
    "example": "Beispiel",
    "inputs": "Inputs",
    "delete": "Delete",
    "info": "Information",
    "nestedTables": "Verschachtelte Tabellen",
    "tables": "Tabellen",
    "editing": "Editieren",
    "paging": "Seitenpaging",
    "activateRTEFunctions": "Funktionen aktivieren",
    "booleanFields": "Ja / Nein - Felder",
    "enumFields": "Auswahl-Felder",
    "referenceFields": "Referenz-Felder",
    "numericFields": "Nummern-Felder",
    "upload": "Bild hochladen",
    "useCroppedImage": "Bildausschnitt verwenden",
    "useOriginImage": "Originalbild verwenden"
  },
  "playground": {
    "titlePrimaryColor": "Primärfarbe",
    "tooltipPrimaryColor": "Passend zu Ihrer Corporate Idendity können Sie an dieser Stelle eine Primärfarbe definieren. Diese wird an verschieden Stellen innerhalb der Anwendung angewandt und markiert die Primärfunktion.",
    "titleLogo": "Logo",
    "tooltipLogo": "Sie haben die Möglichkeit Ihr eigenes Logo hochzuladen um so die Anwendung weiter zu individualisieren.",
    "titleButtons": "Buttons",
    "tooltipButtons": "Es stehen standardmäßig 3 verschiedene Buttonstyles zur Verfügung. Ihr Aussehen kann individuell angepasst werden.",
    "titleInputs": "Eingabefelder",
    "tooltipInputs": "Es stehen standardmäßig 3 verschiedene Eingabefelder zur Verfügung. Ihr Aussehen kann individuell angepasst werden.",
    "titleIconsDelete": "Delete Icons",
    "tooltipIconsDelete": "Ändern Sie das Aussehen der „Lösch“-Icons.",
    "titleIconsInformation": "Informations Icons",
    "tooltipIconsInformation": "Ändern Sie das Aussehen der „Info“-Icons.",
    "titleFilters": "Filter",
    "tooltipFilters": "Abhängig von der aktuellen Tabelle können zusätzliche Filter angezeigt werden, die das Durchsuchen der Einträge erleichtern.",
    "titleNestedTables": "Verschachtelte Tabellen",
    "tooltipNestedTables": "Begrenzen Sie, wie viel Text oder Bilder in der Tabelle zu sehen sind.",
    "titlePaging": "Seitenpaging",
    "tooltipPaging": "Um das vertikale Scrolling innerhalb der Anwendung zu minimieren, dient die Seitenpaginierung. Sie haben die Möglichkeit einzustellen, wie viele Zeilen einer Tabelle dargestellt werden (Seitengröße) und in welchen Schritten der Seitenwechsel stattfinden soll (Seitengrößen-Optionen).",
    "titleImageCropper": "Image Cropper",
    "tooltipImageCropper": "Mithilfe des ImageCroppers können Bilder beim Hochladen redaktionell bearbeitet werden. Die Funktion können sie De- oder Aktivieren.",
    "titleBooleanFields": "Ja/Nein Felder",
    "tooltipBooleanFields": "Ändern Sie das Aussehen der Boolean-Felder.",
    "titleEnumFields": "Auswahl-Felder",
    "tooltipEnumFields": "Ändern Sie das Aussehen der Auswahlfelder.",
    "titleReferenceFields": "Referenz-Felder",
    "tooltipReferenceFields": "Ändern Sie die Art und Weise, wie Sie Referenzen auswählen.",
    "titleNumberFields": "Nummernfelder",
    "tooltipNumberFields": "Entscheiden Sie, ob Nummernfelder zusätzliche Knöpfe zum Hoch- und Heruntersetzen der Zahl anzeigen.",
    "titleTextFields": "Textfelder",
    "tooltipTextFields": "Konfigurieren Sie die Textfelder in der Anwendung indem Sie eine minimale Höhe festlegen. Anstatt das Textfeld für nicht bearbeitbare Werte zu deaktivieren, können diese Felder auch als normaler Text dargestellt werden.",
    "titleTextEditorFunctions": "Texteditor Funktionen",
    "tooltipTextEditorFunctions": "Stellen Sie ein, welche Funktionen des RTE (Rich Text Editors) benötigt werden.",
    "titleDefault": "Noch Fragen?",
    "tooltipDefault": "Wenden Sie sich an uns!"
  },
  "colors": {
    "red": "Rot",
    "green": "Grün",
    "blue": "Blau"
  },
  "dashboard": {
    "greeting": "Hallo, {{user}}!",
    "welcomeBack": "Willkomen zurück im Admin-Bereich.",
    "customers": "Kunden",
    "orders": "Bestellungen",
    "totalValue": "Gesamtwert",
    "products": "Produkte",
    "newestCustomers": "Neuste Kunden",
    "customerNumber": "Kundennummer",
    "company": "Firma",
    "city": "Ort",
    "country": "Land",
    "newestOrderings":"Letzte Bestellungen",
    "orderingNumber": "Bestellnummer",
    "orderingState": "Status",
    "deliveredOn": "Lieferdatum",
    "price": "Gesamtpreis",
    "Ordered": "Bestellt",
    "Paid": "Bezahlt",
    "Delivered": "Geliefert",
    "Canceled": "Storniert"
  }

}
