"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var getDefaultAppTheme = function (darkMode, color) {
    var lightBlueTheme = {
        typography: {
            fontSize: 12,
        },
        overrides: {
            MuiAutocomplete: {
                option: {
                    '&[data-focus="true"]': {
                        color: '#3893C8',
                    },
                },
            },
            MuiOutlinedInput: {
                root: {
                    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                        border: '2px solid',
                        borderColor: '#3893C8',
                    },
                    '&$focused $notchedOutline': {
                        borderColor: '#3893C8',
                        borderWidth: 1,
                    },
                },
            },
            MuiTabs: {
                root: {
                    backgroundColor: '#f5f5f5',
                    borderTop: '2px solid',
                    borderColor: '#ebe7e9',
                },
            },
            MuiTab: {
                root: {
                    '&$selected': {
                        backgroundColor: '#ffffff',
                        borderTop: '3px solid',
                        borderColor: '#3893C8',
                        color: '#3893C8',
                    },
                },
            },
            MuiButton: {
                root: {
                    border: '1px solid #3893C8',
                    color: '#3893C8',
                    minWidth: '0',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #3893C8',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #3893C8',
                    color: '#3893C8',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #3893C8',
                    },
                },
                outlinedPrimary: {
                    color: '#ffffff',
                    backgroundColor: '#3893C8',
                    border: '1px solid #3893C8',
                    '&:hover': {
                        backgroundColor: '#2985ba',
                        border: '1px solid #2985ba',
                    },
                },
            },
            MuiPickersToolbarButton: {
                toolbarBtn: {
                    border: 'none',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: 'none',
                    },
                },
            },
            MuiPickersToolbarText: {
                toolbarTxt: {
                    '&:hover': {
                        color: '#3893C8',
                    },
                },
            },
        },
        palette: {
            common: {
                white: '#ffffff',
                black: '#000000',
            },
            grey: {
                A100: '#f7f7f7',
                A200: '#ebe7e9',
                A700: '#0e1121',
            },
            background: {
                default: '#fafafa',
                paper: '#ffffff',
            },
            primary: {
                main: '#2a3042',
                light: '#3893C8',
                dark: '#262b3b',
            },
            secondary: {
                main: '#3893C8',
            },
        },
    };
    var lightGreenTheme = {
        typography: {
            fontSize: 12,
        },
        overrides: {
            MuiButton: {
                root: {
                    border: '1px solid #558b2f',
                    color: '#7cb342',
                    minWidth: '0',
                    boxShadow: 'none',
                    margin: '0 8px',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #558b2f',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #558b2f',
                    color: '#7cb342',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #558b2f',
                    },
                },
                outlinedPrimary: {
                    color: '#ffffff',
                    backgroundColor: '#7cb342',
                    border: '1px solid #3893C8',
                    '&:hover': {
                        backgroundColor: '#558b2f',
                        border: '1px solid #2985ba',
                    },
                },
            },
        },
        palette: {
            common: {
                white: '#ffffff',
                black: '#000000',
            },
            grey: {
                A100: '#f7f7f7',
                A200: '#ebe7e9',
                A700: '#0e1121',
            },
            background: {
                default: '#fafafa',
            },
            primary: {
                main: '#558b2f',
                light: '#7cb342',
                dark: '#33691e',
            },
            secondary: {
                main: '#7cb342',
            },
        },
    };
    var lightGreyTheme = {
        typography: {
            fontSize: 12,
        },
        overrides: {
            MuiButton: {
                root: {
                    border: '1px solid #616161',
                    color: '#616161',
                    minWidth: '0',
                    boxShadow: 'none',
                    margin: '0 8px',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #616161',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #616161',
                    color: '#616161',
                    '&:hover': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #424242',
                    },
                },
                outlinedPrimary: {
                    color: '#ffffff',
                    backgroundColor: '#616161',
                    border: '1px solid #616161',
                    '&:hover': {
                        backgroundColor: '#424242',
                        border: '1px solid #424242',
                    },
                },
            },
        },
        palette: {
            common: {
                white: '#ffffff',
                black: '#000000',
            },
            grey: {
                A100: '#f5f5f5',
                A200: '#ebe7e9',
                A700: '#9e9fa6',
            },
            background: {
                default: '#fafafa',
            },
            primary: {
                main: '#616161',
                light: '#757575',
                dark: '#424242',
            },
            secondary: {
                main: '#616161',
            },
        },
    };
    var darkTheme = {
        typography: {
            fontSize: 12,
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        border: '1px solid #ff9800',
                        minWidth: '0',
                        boxShadow: 'none',
                    },
                    text: {
                        color: '#ff9800',
                    },
                },
            },
        },
        palette: {
            mode: 'dark',
            primary: {
                main: '#ffb74d',
                light: 'rgba(255, 255, 255, 0.5)',
                dark: '#121212',
            },
            grey: {
                A100: 'transparent',
                A200: 'rgba(255, 255, 255, 0.08)',
            },
            secondary: {
                main: '#ff9800',
            },
            background: {
                default: '#121212',
                paper: '#39465c',
            },
            text: {
                primary: 'rgba(255, 255, 255, 0.5)',
                secondary: 'rgba(255, 255, 255, 0.3)',
            },
        },
    };
    var lightTheme = (color === 'blue') ? lightBlueTheme : ((color === 'grey') ? lightGreyTheme : lightGreenTheme);
    var theme = material_1.createTheme(darkMode ? darkTheme : lightTheme);
    return theme;
};
exports.default = getDefaultAppTheme;
