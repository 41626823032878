"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var schemaTypes_1 = require("../../../shared/types/schemaTypes");
var validateEnityData = function (data, entitySchema, id) {
    var valid = true;
    Object.keys(data.data).map(function (column) {
        var columnSchema = entitySchema && entitySchema.columns.find(function (schema) { return schema.name === column; });
        // schema prüfen > columnType
        if (columnSchema && !columnSchema.nullable && !columnSchema.readOnly) {
            switch (columnSchema.columnType) {
                case schemaTypes_1.ColumnType.Boolean:
                    if (!(typeof data.data[column] === 'boolean')) {
                        valid = false;
                    }
                    break;
                case schemaTypes_1.ColumnType.Number:
                    if (!(typeof data.data[column] === 'number')) {
                        valid = false;
                    }
                    break;
                case schemaTypes_1.ColumnType.Date:
                case schemaTypes_1.ColumnType.Time:
                case schemaTypes_1.ColumnType.DateTime:
                    if (!(typeof data.data[column] === 'number')) {
                        valid = false;
                    }
                    break;
                case schemaTypes_1.ColumnType.Json:
                    if (!(typeof data.data[column] === 'string')) {
                        valid = false;
                    }
                    break;
                case schemaTypes_1.ColumnType.String:
                    if (!data.data[column] || !(typeof data.data[column] === 'string') || data.data[column] === '') {
                        valid = false;
                    }
                    break;
                case schemaTypes_1.ColumnType.HtmlRichText:
                    if (!data.data[column] || !(typeof data.data[column] === 'string') || data.data[column] === '<p><br></p>') {
                        valid = false;
                    }
                    break;
                case schemaTypes_1.ColumnType.Enum:
                    if (!(typeof data.data[column] === 'string' || typeof data.data[column] === 'number')) {
                        valid = false;
                    }
                    break;
                case schemaTypes_1.ColumnType.Files:
                    if (!(typeof data.data[column] === 'string')) {
                        valid = false;
                    }
                    break;
            }
        }
    });
    return valid;
};
exports.default = validateEnityData;
