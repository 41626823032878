"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * isListLevelAvailable
 */
var isListLevelAvailable = function (treeViewLicense, dataDisplayMode, maxTreeViewLevel, treeViewLevel) {
    var listLevelAvailable = typeof (treeViewLevel) === 'number' ? (treeViewLevel + 1 <= maxTreeViewLevel) : false;
    return treeViewLicense ? listLevelAvailable : false;
};
exports.default = isListLevelAvailable;
