"use strict";
/**
 * getDefaultFormat nutzt aktuell moment um Dates zu formatieren
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Format = void 0;
var Format;
(function (Format) {
    Format["date"] = "date";
    Format["dateTime"] = "dateTime";
    Format["time"] = "time";
})(Format = exports.Format || (exports.Format = {}));
var getDefaultFormat = function (format) {
    switch (format) {
        case Format.date:
            return 'dd.MM.yyyy';
        case Format.time:
            return 'HH:mm';
        case Format.dateTime:
            return 'dd.MM.yyyy HH:mm';
    }
};
exports.default = getDefaultFormat;
