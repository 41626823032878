"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var commonTypes_1 = require("../types/commonTypes");
/**
 * isEditLevelAvailable
 */
var isEditLevelAvailable = function (dataViewType, maxTreeViewLevel, treeViewLevel) {
    var editLevelAvailable = typeof (treeViewLevel) === 'number' ? (treeViewLevel === 0 || (dataViewType === commonTypes_1.DataViewType.fullView)) : false;
    return editLevelAvailable;
};
exports.default = isEditLevelAvailable;
