"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnType = exports.ColumnSubType = void 0;
var ColumnSubType;
(function (ColumnSubType) {
    ColumnSubType["UUID"] = "UUID";
    ColumnSubType["Email"] = "Email";
    ColumnSubType["Currency"] = "Currency";
    ColumnSubType["PhoneNumber"] = "PhoneNumber";
    ColumnSubType["Image"] = "Image";
    ColumnSubType["Video"] = "Video";
    ColumnSubType["Document"] = "Document";
    ColumnSubType["Text"] = "Text";
})(ColumnSubType = exports.ColumnSubType || (exports.ColumnSubType = {}));
var ColumnType;
(function (ColumnType) {
    ColumnType["String"] = "String";
    ColumnType["Number"] = "Number";
    ColumnType["Date"] = "Date";
    ColumnType["DateTime"] = "DateTime";
    ColumnType["Time"] = "Time";
    ColumnType["Enum"] = "Enum";
    ColumnType["Boolean"] = "Boolean";
    ColumnType["Json"] = "Json";
    ColumnType["Files"] = "Files";
    ColumnType["HtmlRichText"] = "HtmlRichText";
})(ColumnType = exports.ColumnType || (exports.ColumnType = {}));
