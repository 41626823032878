"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getTableColumns = function (entityColumns, entitySchema) {
    var counter = 0;
    entityColumns.map(function (elem) {
        if (!elem.hideInTableView) {
            counter += 1;
        }
    });
    if (entitySchema.treeViewIncomingReference) {
        counter += 1;
    }
    return counter;
};
exports.default = getTableColumns;
