"use strict";
/**
 * formatCurrency
 */
Object.defineProperty(exports, "__esModule", { value: true });
var formatCurrency = function (currency) {
    var euroGerman = Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });
    return euroGerman.format(currency);
};
exports.default = formatCurrency;
